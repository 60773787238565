import validate from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  access: () => import("/usr/src/dashbboard-version-3/middleware/access.ts"),
  guest: () => import("/usr/src/dashbboard-version-3/middleware/guest.ts"),
  auth: () => import("/usr/src/dashbboard-version-3/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs")
}
import clientAccount from "account-client";

export default defineNuxtPlugin((nuxtApp) => {
  const auth = useAuth();
  const clientsStore = useClientsStore();
  const validationStore = useValidationInputStore();
  const runtimeConfig = useRuntimeConfig();
  const client = clientAccount({
    baseURL: runtimeConfig.public.accountsApiUrl,
  });

  client.axios.interceptors.request.use((config) => {
    config.headers.ClientId = runtimeConfig.clientId || clientsStore.selectedClientId;
    config.headers.Authorization = auth.strategy.token.get();

    validationStore.resetFields();

    return config;
  });
  
  client.axios.interceptors.response.use(
    (response: object) => response,
    (error: object) => {
      if (error.response.status === 422 && typeof error.response.data === 'object') {
        if ('errors' in error.response.data) {
          validationStore.setInvalidFields(error.response.data.errors);
        }
      }

      return Promise.reject(error);
    }
  );
  
  nuxtApp.provide('clientAccount', client)
});
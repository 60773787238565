import Cookies from "js-cookie";
import { useInsightsStore } from "~/stores/insights"; // Import insights store


import { fetchDefaultFulfilmentData } from "~/composables/fetchDefaultFulfilmentData";

export const useClientsStore = defineStore("clients", {
  state: () => ({
    _selectedId: null,
    _clients: [],
    _selectedClient: null, // Changed from an array to a single object (more logical)
    _locale: "us",
    _invoiceLocale: null,
    _currency: "USD",

    // Dashboard graphs
    _dashboardGraphsVisibility: false,
    _dashboardGraphsLoaded: 1,
    _dashboardGraphsLoading: false,

    // Client selector modal
    _modalChangeClientVisibility: false,
  }),

  getters: {
    selectedClientId: (state) => state._selectedId,
    selectedClient: (state) => state._selectedClient,
    clients: (state) => state._clients,
    locale: (state) => state._locale,
    invoiceLocale: (state) => state._invoiceLocale,
    currency: (state) => state._currency,
    visibilityModalChangeClient: (state) => state._modalChangeClientVisibility,
    dashboardGraphsVisibility: (state) => state._dashboardGraphsVisibility,
    dashboardGraphsLoading: (state) => state._dashboardGraphsLoading,
  },

  actions: {
    // Update the invoice locale
    setInvoiceLocale(data: string) {
      this._invoiceLocale = data;
    },

    // Set visibility for the "Change Client" modal
    updateVisibility(visibility: boolean) {
      this._modalChangeClientVisibility = visibility;
    },

    // Increment the total number of dashboard graphs loaded
    updateTotalGraphsLoaded() {
      this._dashboardGraphsLoaded += 1;

      if (this._dashboardGraphsLoaded === 4) {
        this._dashboardGraphsLoading = false;
        this._dashboardGraphsLoaded = 1;
      }
    },

    // Reset dashboard graphs state
    resetDashboardGraphs() {
      this._dashboardGraphsVisibility = false;
      this._dashboardGraphsLoaded = 1;
      this._dashboardGraphsLoading = false;
    },

    // Set dashboard graphs visibility
    setDashboardGraphsVisibility(visibility: boolean) {
      this._dashboardGraphsVisibility = visibility;
    },

    // Set clients and update the selected client
    async setClients(data: any) {
      this._clients = data;
      if (data && data.length > 0) {
        await this.updateSelectedClient(data[0].id);
      }
    },

    // Fetch data for the selected client
    async getSelectedClientData(clientId: string) {
      const { $clientAccount } = useNuxtApp();

      if (!clientId) {
        return;
      }

      try {
        const response = await $clientAccount.clients.item(clientId);

        if (response.status === 200) {
          const responseData = response.data;
          this._selectedClient = responseData;
          updateDefaultFulfilmentData(clientId);
        }
      } catch (error) {
        // Error handling kept but without logging
      }
    },

    // Update the selected client
    async updateSelectedClient(clientId: string) {
      Cookies.set("selected_client_id", clientId, { expires: 365 });
      this._selectedId = clientId;
      await this.getSelectedClientData(clientId);
    },

    // Set selected client explicitly
    async setSelectedClient(clientId: string) {
      Cookies.set("selected_client_id", clientId, { expires: 365 });
      this._selectedId = clientId;
      await this.getSelectedClientData(clientId);
    },

    // Reset the entire store to its initial state
    resetStore() {
      // Reset all state properties to their default values
      this._selectedId = null;
      this._clients = [];
      this._selectedClient = null;
      this._locale = "us";
      this._invoiceLocale = null;
      this._currency = "USD";

      // Reset dashboard graphs state
      this._dashboardGraphsVisibility = false;
      this._dashboardGraphsLoaded = 1;
      this._dashboardGraphsLoading = false;

      // Reset client selector modal
      this._modalChangeClientVisibility = false;

      // Clear the client selection cookie
      Cookies.remove("selected_client_id");
    },
  },
});

function updateDefaultFulfilmentData(clientId: string) {
  const insightsStore = useInsightsStore();

  insightsStore.getSelectedOMSShop();
  insightsStore.getSelectedShopGroup();
  insightsStore.getSelectedShopOwner();
  insightsStore.getSelectedDateRange();

  const shops = insightsStore.selectedOMSShop;
  const shopGroups = insightsStore.selectedShopGroup;
  const shopOwners = insightsStore.selectedShopOwner;
  const dateRange = insightsStore.selectedDateRange;

  if (shops.length === 0) {
    fetchDefaultFulfilmentData(clientId, "shops");
  }
  if (shopGroups.length === 0) {
    fetchDefaultFulfilmentData(clientId, "shopGroups");
  }
  if (shopOwners.length === 0) {
    fetchDefaultFulfilmentData(clientId, "shopOwners");
  }
  if (dateRange.length === 0) {
    fetchDefaultFulfilmentData(clientId, "dateRange");
  }
}

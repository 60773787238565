import * as CompanyPermissions from '@/constants/permissions/company'
import * as DashboardPermissions from '@/constants/permissions/dashboard'
import * as InvoicesPermissions from '@/constants/permissions/invoices'
import * as NotificationsPermissions from '@/constants/permissions/notifications'
import * as ReportsPermissions from '@/constants/permissions/reports'
import * as ServicesPermissions from '@/constants/permissions/services'
import * as TasksPermissions from '@/constants/permissions/tasks'
import * as TicketsPermissions from '@/constants/permissions/tickets'

// Combine all permissions into a single object
const permissions = {
  ...CompanyPermissions,
  ...DashboardPermissions,
  ...InvoicesPermissions,
  ...NotificationsPermissions,
  ...ReportsPermissions,
  ...ServicesPermissions,
  ...TasksPermissions,
  ...TicketsPermissions,
}

export default defineNuxtPlugin((nuxtApp) => {
  // Inject the permissions object into the app
  nuxtApp.provide('permission', permissions)
})

import { castArray, includes, lowerCase, some } from 'lodash'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('hasRole', (roleItem: string | string[]) => {
    let allow = false;
    const role = castArray(roleItem);

    const authState = useAuth();

    const userRoles = authState.user.roles.map((item) => lowerCase(item));

    // Check if userRoles isn't empty and if given role(s) exists
    if (userRoles.length > 0 && role.length > 0) {
      allow = some(userRoles, (item) => {
        return includes(role, item);
      });

      /**
       * User has no access
       * If user is admin, overwrite and give access
       */
      if (!allow) {
        allow = some(userRoles, (item) => {
          return includes('admin', item);
        });
      }
    }

    return allow;
  });
});

import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { Oauth2Scheme } from '#auth/runtime'

// Options
let options = {
    "globalMiddleware": false,
    "enableMiddleware": true,
    "resetOnError": false,
    "resetOnResponseError": false,
    "ignoreExceptions": false,
    "scopeKey": "scope",
    "rewriteRedirects": true,
    "fullPathRedirect": false,
    "redirectStrategy": "storage",
    "watchLoggedIn": true,
    "tokenValidationInterval": false,
    "redirect": {
        "login": "\u002Flogin",
        "logout": "https:\u002F\u002Faccount.salesupply.com\u002Flogout",
        "home": "\u002F",
        "callback": "\u002Flogin"
    },
    "stores": {
        "state": {
            "namespace": "auth"
        },
        "pinia": {
            "enabled": false,
            "namespace": "auth"
        },
        "cookie": {
            "enabled": true,
            "prefix": "auth.",
            "options": {
                "path": "\u002F",
                "sameSite": "lax",
                "maxAge": 31536000
            },
            "secure": true
        },
        "local": {
            "enabled": false,
            "prefix": "auth."
        },
        "session": {
            "enabled": false,
            "prefix": "auth."
        }
    },
    "defaultStrategy": "social"
}

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('social', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['social'], {"endpoints":{"authorization":"https://account.salesupply.com/platform/login?client_id=993c01b0-5e98-4f55-bcc5-5247b5c8b307","token":"https://account.salesupply.com/platform/authorize","userInfo":"https://account.salesupply.com/api/v1/me","logout":"https://account.salesupply.com/logout"},"token":{"property":"access_token","type":"Bearer","maxAge":1800},"refreshToken":{"property":"refresh_token","maxAge":2592000},"responseType":"code","grantType":"authorization_code","clientId":"993c01b0-5e98-4f55-bcc5-5247b5c8b307","codeChallengeMethod":"S256","redirectUri":"dashboard.salesupply.com","name":"social","ssr":false})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})

// @ts-nocheck
import locale__usr_src_dashbboard_version_3_i18n_locales_en_account_js from "../i18n/locales/en/account.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_base_forms_js from "../i18n/locales/en/base_forms.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_breadcrumb_js from "../i18n/locales/en/breadcrumb.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_button_js from "../i18n/locales/en/button.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_changelog_js from "../i18n/locales/en/changelog.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_client_js from "../i18n/locales/en/client.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_company_js from "../i18n/locales/en/company.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_contact_js from "../i18n/locales/en/contact.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_contacts_js from "../i18n/locales/en/contacts.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_countries_js from "../i18n/locales/en/countries.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_currencies_js from "../i18n/locales/en/currencies.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_currency_js from "../i18n/locales/en/currency.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_dashboard_js from "../i18n/locales/en/dashboard.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_datetime_js from "../i18n/locales/en/datetime.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_documents_js from "../i18n/locales/en/documents.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_errors_js from "../i18n/locales/en/errors.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_fulfillment_settings_js from "../i18n/locales/en/fulfillment_settings.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_help_center_js from "../i18n/locales/en/help_center.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_information_js from "../i18n/locales/en/information.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_insights_js from "../i18n/locales/en/insights.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_invoices_js from "../i18n/locales/en/invoices.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_login_js from "../i18n/locales/en/login.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_manuals_js from "../i18n/locales/en/manuals.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_marketplace_js from "../i18n/locales/en/marketplace.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_notifications_js from "../i18n/locales/en/notifications.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_onboarding_js from "../i18n/locales/en/onboarding.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_opening_hours_js from "../i18n/locales/en/opening_hours.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_reports_js from "../i18n/locales/en/reports.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_roadmap_js from "../i18n/locales/en/roadmap.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_services_js from "../i18n/locales/en/services.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_settings_js from "../i18n/locales/en/settings.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_shop_js from "../i18n/locales/en/shop.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_status_js from "../i18n/locales/en/status.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_tasks_js from "../i18n/locales/en/tasks.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_team_members_js from "../i18n/locales/en/team_members.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_tickets_js from "../i18n/locales/en/tickets.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_unsaved_changes_js from "../i18n/locales/en/unsaved_changes.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_validation_js from "../i18n/locales/en/validation.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_en_wallet_js from "../i18n/locales/en/wallet.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_account_js from "../i18n/locales/nl/account.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_base_forms_js from "../i18n/locales/nl/base_forms.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_breadcrumb_js from "../i18n/locales/nl/breadcrumb.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_button_js from "../i18n/locales/nl/button.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_changelog_js from "../i18n/locales/nl/changelog.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_client_js from "../i18n/locales/nl/client.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_company_js from "../i18n/locales/nl/company.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_contact_js from "../i18n/locales/nl/contact.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_contacts_js from "../i18n/locales/nl/contacts.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_countries_js from "../i18n/locales/nl/countries.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_currencies_js from "../i18n/locales/nl/currencies.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_currency_js from "../i18n/locales/nl/currency.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_dashboard_js from "../i18n/locales/nl/dashboard.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_datetime_js from "../i18n/locales/nl/datetime.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_documents_js from "../i18n/locales/nl/documents.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_errors_js from "../i18n/locales/nl/errors.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_fulfillment_settings_js from "../i18n/locales/nl/fulfillment_settings.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_help_center_js from "../i18n/locales/nl/help_center.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_information_js from "../i18n/locales/nl/information.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_insights_js from "../i18n/locales/nl/insights.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_invoices_js from "../i18n/locales/nl/invoices.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_login_js from "../i18n/locales/nl/login.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_manuals_js from "../i18n/locales/nl/manuals.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_marketplace_js from "../i18n/locales/nl/marketplace.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_notifications_js from "../i18n/locales/nl/notifications.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_onboarding_js from "../i18n/locales/nl/onboarding.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_opening_hours_js from "../i18n/locales/nl/opening_hours.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_reports_js from "../i18n/locales/nl/reports.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_roadmap_js from "../i18n/locales/nl/roadmap.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_services_js from "../i18n/locales/nl/services.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_settings_js from "../i18n/locales/nl/settings.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_shop_js from "../i18n/locales/nl/shop.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_status_js from "../i18n/locales/nl/status.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_tasks_js from "../i18n/locales/nl/tasks.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_team_members_js from "../i18n/locales/nl/team_members.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_tickets_js from "../i18n/locales/nl/tickets.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_unsaved_changes_js from "../i18n/locales/nl/unsaved_changes.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_validation_js from "../i18n/locales/nl/validation.js";
import locale__usr_src_dashbboard_version_3_i18n_locales_nl_wallet_js from "../i18n/locales/nl/wallet.js";


export const localeCodes =  [
  "en",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/locales/en/account.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_account_js), cache: true },
{ key: "../i18n/locales/en/base_forms.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_base_forms_js), cache: true },
{ key: "../i18n/locales/en/breadcrumb.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_breadcrumb_js), cache: true },
{ key: "../i18n/locales/en/button.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_button_js), cache: true },
{ key: "../i18n/locales/en/changelog.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_changelog_js), cache: true },
{ key: "../i18n/locales/en/client.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_client_js), cache: true },
{ key: "../i18n/locales/en/company.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_company_js), cache: true },
{ key: "../i18n/locales/en/contact.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_contact_js), cache: true },
{ key: "../i18n/locales/en/contacts.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_contacts_js), cache: true },
{ key: "../i18n/locales/en/countries.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_countries_js), cache: true },
{ key: "../i18n/locales/en/currencies.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_currencies_js), cache: true },
{ key: "../i18n/locales/en/currency.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_currency_js), cache: true },
{ key: "../i18n/locales/en/dashboard.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_dashboard_js), cache: true },
{ key: "../i18n/locales/en/datetime.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_datetime_js), cache: true },
{ key: "../i18n/locales/en/documents.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_documents_js), cache: true },
{ key: "../i18n/locales/en/errors.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_errors_js), cache: true },
{ key: "../i18n/locales/en/fulfillment_settings.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_fulfillment_settings_js), cache: true },
{ key: "../i18n/locales/en/help_center.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_help_center_js), cache: true },
{ key: "../i18n/locales/en/information.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_information_js), cache: true },
{ key: "../i18n/locales/en/insights.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_insights_js), cache: true },
{ key: "../i18n/locales/en/invoices.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_invoices_js), cache: true },
{ key: "../i18n/locales/en/login.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_login_js), cache: true },
{ key: "../i18n/locales/en/manuals.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_manuals_js), cache: true },
{ key: "../i18n/locales/en/marketplace.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_marketplace_js), cache: true },
{ key: "../i18n/locales/en/notifications.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_notifications_js), cache: true },
{ key: "../i18n/locales/en/onboarding.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_onboarding_js), cache: true },
{ key: "../i18n/locales/en/opening_hours.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_opening_hours_js), cache: true },
{ key: "../i18n/locales/en/reports.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_reports_js), cache: true },
{ key: "../i18n/locales/en/roadmap.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_roadmap_js), cache: true },
{ key: "../i18n/locales/en/services.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_services_js), cache: true },
{ key: "../i18n/locales/en/settings.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_settings_js), cache: true },
{ key: "../i18n/locales/en/shop.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_shop_js), cache: true },
{ key: "../i18n/locales/en/status.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_status_js), cache: true },
{ key: "../i18n/locales/en/tasks.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_tasks_js), cache: true },
{ key: "../i18n/locales/en/team_members.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_team_members_js), cache: true },
{ key: "../i18n/locales/en/tickets.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_tickets_js), cache: true },
{ key: "../i18n/locales/en/unsaved_changes.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_unsaved_changes_js), cache: true },
{ key: "../i18n/locales/en/validation.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_validation_js), cache: true },
{ key: "../i18n/locales/en/wallet.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_en_wallet_js), cache: true }],
  "nl": [{ key: "../i18n/locales/nl/account.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_account_js), cache: true },
{ key: "../i18n/locales/nl/base_forms.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_base_forms_js), cache: true },
{ key: "../i18n/locales/nl/breadcrumb.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_breadcrumb_js), cache: true },
{ key: "../i18n/locales/nl/button.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_button_js), cache: true },
{ key: "../i18n/locales/nl/changelog.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_changelog_js), cache: true },
{ key: "../i18n/locales/nl/client.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_client_js), cache: true },
{ key: "../i18n/locales/nl/company.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_company_js), cache: true },
{ key: "../i18n/locales/nl/contact.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_contact_js), cache: true },
{ key: "../i18n/locales/nl/contacts.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_contacts_js), cache: true },
{ key: "../i18n/locales/nl/countries.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_countries_js), cache: true },
{ key: "../i18n/locales/nl/currencies.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_currencies_js), cache: true },
{ key: "../i18n/locales/nl/currency.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_currency_js), cache: true },
{ key: "../i18n/locales/nl/dashboard.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_dashboard_js), cache: true },
{ key: "../i18n/locales/nl/datetime.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_datetime_js), cache: true },
{ key: "../i18n/locales/nl/documents.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_documents_js), cache: true },
{ key: "../i18n/locales/nl/errors.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_errors_js), cache: true },
{ key: "../i18n/locales/nl/fulfillment_settings.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_fulfillment_settings_js), cache: true },
{ key: "../i18n/locales/nl/help_center.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_help_center_js), cache: true },
{ key: "../i18n/locales/nl/information.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_information_js), cache: true },
{ key: "../i18n/locales/nl/insights.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_insights_js), cache: true },
{ key: "../i18n/locales/nl/invoices.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_invoices_js), cache: true },
{ key: "../i18n/locales/nl/login.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_login_js), cache: true },
{ key: "../i18n/locales/nl/manuals.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_manuals_js), cache: true },
{ key: "../i18n/locales/nl/marketplace.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_marketplace_js), cache: true },
{ key: "../i18n/locales/nl/notifications.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_notifications_js), cache: true },
{ key: "../i18n/locales/nl/onboarding.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_onboarding_js), cache: true },
{ key: "../i18n/locales/nl/opening_hours.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_opening_hours_js), cache: true },
{ key: "../i18n/locales/nl/reports.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_reports_js), cache: true },
{ key: "../i18n/locales/nl/roadmap.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_roadmap_js), cache: true },
{ key: "../i18n/locales/nl/services.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_services_js), cache: true },
{ key: "../i18n/locales/nl/settings.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_settings_js), cache: true },
{ key: "../i18n/locales/nl/shop.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_shop_js), cache: true },
{ key: "../i18n/locales/nl/status.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_status_js), cache: true },
{ key: "../i18n/locales/nl/tasks.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_tasks_js), cache: true },
{ key: "../i18n/locales/nl/team_members.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_team_members_js), cache: true },
{ key: "../i18n/locales/nl/tickets.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_tickets_js), cache: true },
{ key: "../i18n/locales/nl/unsaved_changes.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_unsaved_changes_js), cache: true },
{ key: "../i18n/locales/nl/validation.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_validation_js), cache: true },
{ key: "../i18n/locales/nl/wallet.js", load: () => Promise.resolve(locale__usr_src_dashbboard_version_3_i18n_locales_nl_wallet_js), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/usr/src/dashbboard-version-3/i18n/locales/en/account.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/base_forms.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/breadcrumb.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/button.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/changelog.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/client.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/company.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/contact.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/contacts.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/countries.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/currencies.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/currency.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/dashboard.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/datetime.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/documents.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/errors.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/fulfillment_settings.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/help_center.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/information.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/insights.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/invoices.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/login.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/manuals.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/marketplace.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/notifications.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/onboarding.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/opening_hours.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/reports.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/roadmap.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/services.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/settings.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/shop.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/status.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/tasks.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/team_members.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/tickets.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/unsaved_changes.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/validation.js",
        "/usr/src/dashbboard-version-3/i18n/locales/en/wallet.js"
      ]
    },
    {
      "code": "nl",
      "name": "Dutch",
      "files": [
        "/usr/src/dashbboard-version-3/i18n/locales/nl/account.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/base_forms.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/breadcrumb.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/button.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/changelog.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/client.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/company.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/contact.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/contacts.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/countries.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/currencies.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/currency.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/dashboard.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/datetime.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/documents.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/errors.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/fulfillment_settings.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/help_center.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/information.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/insights.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/invoices.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/login.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/manuals.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/marketplace.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/notifications.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/onboarding.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/opening_hours.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/reports.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/roadmap.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/services.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/settings.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/shop.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/status.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/tasks.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/team_members.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/tickets.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/unsaved_changes.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/validation.js",
        "/usr/src/dashbboard-version-3/i18n/locales/nl/wallet.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/account.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/base_forms.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/breadcrumb.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/button.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/changelog.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/client.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/company.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/contact.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/contacts.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/countries.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/currencies.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/currency.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/dashboard.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/datetime.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/documents.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/errors.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/fulfillment_settings.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/help_center.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/information.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/insights.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/invoices.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/login.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/manuals.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/marketplace.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/notifications.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/onboarding.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/opening_hours.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/reports.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/roadmap.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/services.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/settings.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/shop.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/status.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/tasks.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/team_members.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/tickets.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/unsaved_changes.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/validation.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/en/wallet.js"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Dutch",
    "files": [
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/account.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/base_forms.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/breadcrumb.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/button.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/changelog.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/client.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/company.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/contact.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/contacts.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/countries.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/currencies.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/currency.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/dashboard.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/datetime.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/documents.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/errors.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/fulfillment_settings.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/help_center.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/information.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/insights.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/invoices.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/login.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/manuals.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/marketplace.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/notifications.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/onboarding.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/opening_hours.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/reports.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/roadmap.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/services.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/settings.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/shop.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/status.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/tasks.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/team_members.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/tickets.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/unsaved_changes.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/validation.js"
      },
      {
        "path": "/usr/src/dashbboard-version-3/i18n/locales/nl/wallet.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"

export const getDefaultDateRange = () => {
    // Get the current date
  const currentDate:any = new Date()

  // Calculate the date 30 days ago
  const thirtyDaysAgo:any = new Date()
  thirtyDaysAgo.setDate(currentDate.getDate() - 30)

  // Format the dates as strings
  const currentDateString = transformDate(currentDate)
  const thirtyDaysAgoString = transformDate(thirtyDaysAgo)

  // Return the date range string
  return [thirtyDaysAgoString, currentDateString]
}

function transformDate(originalDate: string) {
  if (!originalDate) {
    return
  }
  const date = new Date(originalDate)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
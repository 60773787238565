// stores/insights.js
import Cookies from "js-cookie";
import { defineStore } from "pinia";

export const useInsightsStore = defineStore("insights", {
  state: () => ({
    _selectedOMSShop: [],
    _selectedCMSShop: {},
    _selectedDateRange: [],
    _selectedPeriod: {},
    _selectedShopGroup: [],
    _selectedShopOwner: [],
    _activeCategory: null,
    _fulfilmentIsActive: false,
    _customerCareIsActive: false,
  }),

  getters: {
    selectedOMSShop(state) {
      return state._selectedOMSShop;
    },
    selectedCMSShop(state) {
      return state._selectedCMSShop;
    },
    selectedDateRange(state) {
      return state._selectedDateRange;
    },
    selectedPeriod(state) {
      return state._selectedPeriod;
    },
    selectedShopGroup(state) {
      return state._selectedShopGroup;
    },
    selectedShopOwner(state) {
      return state._selectedShopOwner;
    },
    activeCategory(state) {
      return state._activeCategory;
    },
    fulfilmentIsActive(state) {
      return state._fulfilmentIsActive;
    },
    customerCareIsActive(state) {
      return state._customerCareIsActive;
    },
  },

  actions: {
    async updateSelectedOMSShop(data) {
      this._selectedOMSShop = data;
      saveDataToCookie("selectedOMSShop", data);
    },

    async updateSelectedShopOwner(data) {
      if (!data) {
        return;
      }

      this._selectedShopOwner = data;
      saveDataToCookie("selectedShopOwner", data);
    },

    updateSelectedCMSShop(data) {
      this._selectedCMSShop = data;
      saveDataToCookie("selectedCMSShop", data);
    },

    updateSelectedShopGroup(data) {
      this._selectedShopGroup = data;
      saveDataToCookie("selectedShopGroup", data);
    },

    updateSelectedDateRange(data) {
      this._selectedDateRange = data;
      saveDataToCookie("selectedDateRange", data);
    },

    updateSelectedPeriod(data) {
      this._selectedPeriod = data;
      saveDataToCookie("selectedPeriod", data);
    },

    updateActiveCategory(data) {
      this._activeCategory = data;
      saveDataToCookie("activeCategory", JSON.stringify(data));
    },

    getSelectedOMSShop() {
      const data = loadDataFromCookie("selectedOMSShop");
      if (data) {
        this._selectedOMSShop = data;
      }
    },

    getSelectedShopOwner() {
      const data = loadDataFromCookie("selectedShopOwner");
      if (data) {
        this._selectedShopOwner = data;
      }
    },

    getSelectedShopGroup() {
      const data = loadDataFromCookie("selectedShopGroup");
      if (data) {
        this._selectedShopGroup = data;
      }
    },

    getSelectedCMSShop() {
      const data = loadDataFromCookie("selectedCMSShop");
      if (data) {
        this._selectedCMSShop = data;
      }
    },

    getSelectedDateRange() {
      const data = loadDataFromCookie("selectedDateRange");
      if (data) {
        this._selectedDateRange = data;
      }
    },

    getSelectedPeriod() {
      const data = loadDataFromCookie("selectedPeriod");
      if (data) {
        this._selectedPeriod = data;
      }
    },

    getActiveCategory() {
      const data = loadDataFromCookie("activeCategory");
      if (data) {
        this._activeCategory = data;
      }
    },
  },
});

// Helper functions for handling cookies
function saveDataToCookie(key, data) {
  try {
    Cookies.set(key, JSON.stringify(data), {
      expires: 365,
      sameSite: "Lax",
      domain: window.location.hostname,
      path: "/",
    });
    return true;
  } catch (error) {
    return null;
  }
}

function loadDataFromCookie(key) {
  try {
    const cookie = Cookies.get(key);
    if (cookie) {
      const parsed = JSON.parse(cookie);
      return parsed;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export const useValidationInputStore = defineStore('validation_input', {
  state: () => ({
    // _emptyFields: [],
    // _areRequiredFields: [],
    _invalidFields: {},
    // _alreadyUsedFields: [],
  }),
  actions: {
    // setEmptyFields(emptyFields: string[]) {
    //   this._emptyFields = emptyFields
    // },
    setInvalidFields(invalidFields: object) {
      this._invalidFields = invalidFields;
    },
    // setAlreadyUsedFields(alreadyUsedFields: string[]) {
    //   this._alreadyUsedFields = alreadyUsedFields
    // },
    resetFields() {
      // this._emptyFields = []
      this._invalidFields = {};
      // this._alreadyUsedFields = []
    },
  },
  getters: {
    // emptyFields: (state) => state._emptyFields,
    invalidFields: (state) => state._invalidFields,
    // alreadyUsedFields: (state) => state._alreadyUsedFields,
  }
})

export const fetchDefaultFulfilmentData = async (clientId, target = null) => {
  const { $clientAccount } = useNuxtApp();
  const insightsStore = useInsightsStore();
  const selectedClientId = clientId;

  const extractData = (response) => {
    if (response?.status === 200 && response.data) return response.data;
    throw new Error("Invalid or empty response data");
  };

  const simplifyFulfilmentArray = (entities) => {
    if (!Array.isArray(entities)) return [];
    return entities
      .filter((entity) => entity && typeof entity === "object")
      .map((entity) => ({
        name: entity.name || "",
        third_party_id: entity.third_party_id || "",
      }));
  };
  // Prepare promises
  const shopsPromise =
    !target || target === "shops"
      ? $clientAccount.shop.shops({ client_id: selectedClientId }).then(extractData)
      : Promise.resolve([]);
  const shopGroupsPromise =
    !target || target === "shopGroups"
      ? $clientAccount.shopGroups.list({ client_id: selectedClientId }).then(extractData)
      : Promise.resolve([]);
  const shopOwnersPromise =
    !target || target === "shopOwners"
      ? $clientAccount.shopOwners.list({ client_id: selectedClientId }).then(extractData)
      : Promise.resolve([]);

  // Fetch data directly instead of using useAsyncData
  try {
    const [shops, shopGroups, shopOwners] = await Promise.all([
      shopsPromise,
      shopGroupsPromise,
      shopOwnersPromise,
    ]);

    if (shops.length > 0) insightsStore.updateSelectedOMSShop(simplifyFulfilmentArray(shops));
    if (shopGroups.length > 0) insightsStore.updateSelectedShopGroup(simplifyFulfilmentArray(shopGroups));
    if (shopOwners.length > 0) insightsStore.updateSelectedShopOwner(simplifyFulfilmentArray(shopOwners));

    if (!target || target === "dateRange") {
      insightsStore.updateSelectedDateRange(getDefaultDateRange());
    }
  } catch (err) {
  }
};
export default defineNuxtPlugin((nuxtApp) => {
  // Inject the `hasPermissionTo` method
  nuxtApp.provide('hasPermissionTo', (permissionItem: string | string[]) => {
    let allow = false
    const permission = lodashCastArray(lodashLowerCase(permissionItem))

    // Access the `auth` state
    const auth = useAuth();

    // Ensure the `auth` state and user exist
    if (auth?.user) {
      const userPermissions = auth.user.permissions.map((item: string) =>
        lodashLowerCase(item)
      )

      // Check if user permissions match the required permission(s)
      if (userPermissions.length > 0 && permission.length > 0) {
        allow = lodashSome(userPermissions, (item) => {
          return lodashIncludes(permission, item)
        })
      }

      // If user has no access, check if they are an admin
      if (!allow) {
        const userRoles = auth.user.roles.map((item: string) =>
          lodashLowerCase(item)
        )
        if (userRoles.length > 0) {
          // Grant access if the user has the 'admin' role
          allow = lodashSome(userRoles, (item) => {
            return lodashIncludes('admin', item)
          })
        }
      }
    }

    return allow
  })
})

import { defineNuxtPlugin } from '#app';
import { defineComponent, h } from 'vue';
import { Doughnut } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const plugin = {
  id: 'center-text-plugin',
  beforeDraw(chart) {
    const { ctx, config, chartArea, innerRadius } = chart;
    const centerConfig = config.options.elements?.center;

    if (centerConfig) {
      const { text, fontStyle = 'Arial', color = '#000', maxFontSize = 35, sidePadding = 20 } = centerConfig;
      const sidePaddingCalculated = (sidePadding / 100) * (innerRadius * 2);
      ctx.font = `30px ${fontStyle}`;
      const stringWidth = ctx.measureText(text).width;
      const elementWidth = innerRadius * 2 - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = innerRadius * 2;
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      const lineHeight = centerConfig.lineHeight || 25;
      let wrapText = false;

      if (fontSizeToUse < centerConfig.minFontSize || 20) {
        fontSizeToUse = centerConfig.minFontSize || 20;
        wrapText = true;
      }

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = (chartArea.left + chartArea.right) / 2;
      let centerY = (chartArea.top + chartArea.bottom) / 2;
      ctx.font = `${fontSizeToUse}px ${fontStyle}`;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(text, centerX, centerY);
        return;
      }

      const words = text.split(' ');
      let line = '';
      const lines = [];

      words.forEach((word) => {
        const testLine = line + word + ' ';
        const testWidth = ctx.measureText(testLine).width;

        if (testWidth > elementWidth) {
          lines.push(line);
          line = word + ' ';
        } else {
          line = testLine;
        }
      });

      centerY -= (lines.length / 2) * lineHeight;

      lines.forEach((lineText) => {
        ctx.fillText(lineText, centerX, centerY);
        centerY += lineHeight;
      });

      ctx.fillText(line, centerX, centerY);
    }
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component(
    'DoughnutChart', // Register the component as PascalCase
    defineComponent({
      name: 'DoughnutChart',
      extends: Doughnut,
      props: {
        type: {
          type: String,
          default: 'doughnut',
        },
        data: {
          type: Object,
          required: true,
        },
        options: {
          type: Object,
          required: true,
        },
      },
      watch: {
        data() {
          this.renderChart(this.data, this.options)
        },
      },
      mounted() {
        ChartJS.register(plugin);
        this.renderChart(this.data, this.options);
      },
      render() {
        return h(Doughnut, {
          type: this.type,
          chartData: this.data,
          chartOptions: this.options,
        });
      },
    })
  );
});

import payload_plugin_qLmFnukI99 from "/usr/src/dashbboard-version-3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/dashbboard-version-3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/dashbboard-version-3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/dashbboard-version-3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/usr/src/dashbboard-version-3/.nuxt/floating-vue.mjs";
import plugin_client_OVoKJro5pc from "/usr/src/dashbboard-version-3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import http_plugin_nuxt_BmzkAX86rT from "/usr/src/dashbboard-version-3/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/usr/src/dashbboard-version-3/.nuxt/auth.plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/dashbboard-version-3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/src/dashbboard-version-3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import router_19BuzgFWKZ from "/usr/src/dashbboard-version-3/plugins/router.js";
import chart_doughnut_client_1raDWiDdts from "/usr/src/dashbboard-version-3/plugins/chart-doughnut.client.ts";
import chartjs_1wC9lAM0Tp from "/usr/src/dashbboard-version-3/plugins/chartjs.ts";
import click_outside_D2NiLGp7dJ from "/usr/src/dashbboard-version-3/plugins/click-outside.ts";
import client_account_1kgeabBnVh from "/usr/src/dashbboard-version-3/plugins/client-account.ts";
import constants_2m2NibMVoo from "/usr/src/dashbboard-version-3/plugins/constants.ts";
import floating_vue_client_2c4Sn6eaXt from "/usr/src/dashbboard-version-3/plugins/floating-vue.client.ts";
import hasPermissionTo_5hJJjWJsgg from "/usr/src/dashbboard-version-3/plugins/hasPermissionTo.ts";
import hasRole_w503bzQ6zA from "/usr/src/dashbboard-version-3/plugins/hasRole.ts";
import permissions_MHRAHWKe7t from "/usr/src/dashbboard-version-3/plugins/permissions.ts";
import role_UYVWwTyVIj from "/usr/src/dashbboard-version-3/plugins/role.ts";
import setUserLang_lowlchOTzL from "/usr/src/dashbboard-version-3/plugins/setUserLang.js";
import vue_datepicker_client_IHXPEzuHUc from "/usr/src/dashbboard-version-3/plugins/vue-datepicker.client.ts";
import vue_filepond_client_A9G60z7SJd from "/usr/src/dashbboard-version-3/plugins/vue-filepond.client.ts";
import vue_good_table_client_QJJ3eoo0yA from "/usr/src/dashbboard-version-3/plugins/vue-good-table.client.ts";
import vue_multiselect_client_LlWrtlh9ma from "/usr/src/dashbboard-version-3/plugins/vue-multiselect.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_client_OVoKJro5pc,
  http_plugin_nuxt_BmzkAX86rT,
  auth_plugin_6DDHMgc34l,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  router_19BuzgFWKZ,
  chart_doughnut_client_1raDWiDdts,
  chartjs_1wC9lAM0Tp,
  click_outside_D2NiLGp7dJ,
  client_account_1kgeabBnVh,
  constants_2m2NibMVoo,
  floating_vue_client_2c4Sn6eaXt,
  hasPermissionTo_5hJJjWJsgg,
  hasRole_w503bzQ6zA,
  permissions_MHRAHWKe7t,
  role_UYVWwTyVIj,
  setUserLang_lowlchOTzL,
  vue_datepicker_client_IHXPEzuHUc,
  vue_filepond_client_A9G60z7SJd,
  vue_good_table_client_QJJ3eoo0yA,
  vue_multiselect_client_LlWrtlh9ma
]
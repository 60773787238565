
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93NbBYdjQ3bWMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/inventory/[id].vue?macro=true";
import { default as index7rNC8V0ms5Meta } from "/usr/src/dashbboard-version-3/pages/fulfilment/inventory/index.vue?macro=true";
import { default as _91ordercode_93jFQt1QBPm8Meta } from "/usr/src/dashbboard-version-3/pages/fulfilment/orders/[ordercode].vue?macro=true";
import { default as indexHM2K0KIXquMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/orders/index.vue?macro=true";
import { default as newWyI14v5k4kMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/orders/new.vue?macro=true";
import { default as overviewAFJOq3IHmmMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/overview.vue?macro=true";
import { default as _91id_93a2DDwM3t0DMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/parcels/[id].vue?macro=true";
import { default as indexnAMuLyM5pVMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/parcels/index.vue?macro=true";
import { default as _91sku_93mduq9nezhjMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/products/[sku].vue?macro=true";
import { default as bundleLJq41hR4DQMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/products/bundle.vue?macro=true";
import { default as indexR4sHvvCr4NMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/products/index.vue?macro=true";
import { default as newd82zvOsg1EMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/products/new.vue?macro=true";
import { default as _91id_93860tkeAeFSMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/[id].vue?macro=true";
import { default as indexECuYI364NAMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/index.vue?macro=true";
import { default as newHGRKWJtw7lMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/new.vue?macro=true";
import { default as _91rma_93KPGlW5uwOGMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/returns/[rma].vue?macro=true";
import { default as index1Hp2cf7H15Meta } from "/usr/src/dashbboard-version-3/pages/fulfilment/returns/index.vue?macro=true";
import { default as _91id_93Q6zqj6oceTMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/shipments/[id].vue?macro=true";
import { default as indexNVwJYYBvZLMeta } from "/usr/src/dashbboard-version-3/pages/fulfilment/shipments/index.vue?macro=true";
import { default as indexdeoWTC7zgOMeta } from "/usr/src/dashbboard-version-3/pages/index.vue?macro=true";
import { default as indexWgWAImgG8hMeta } from "/usr/src/dashbboard-version-3/pages/login/index.vue?macro=true";
import { default as indexfN067Sk76tMeta } from "/usr/src/dashbboard-version-3/pages/logout/index.vue?macro=true";
import { default as index2qjP9U3fzFMeta } from "/usr/src/dashbboard-version-3/pages/reports/index.vue?macro=true";
import { default as index6zlxoHVgPgMeta } from "/usr/src/dashbboard-version-3/pages/settings/business/index.vue?macro=true";
import { default as indexa1h9qN33mNMeta } from "/usr/src/dashbboard-version-3/pages/settings/contacts/index.vue?macro=true";
import { default as indexvjaAQEjYUkMeta } from "/usr/src/dashbboard-version-3/pages/settings/import/index.vue?macro=true";
import { default as indexOB8Up0ah80Meta } from "/usr/src/dashbboard-version-3/pages/settings/index.vue?macro=true";
import { default as indexXOKyGQz0zQMeta } from "/usr/src/dashbboard-version-3/pages/settings/team/index.vue?macro=true";
export default [
  {
    name: "fulfilment-inventory-id",
    path: "/fulfilment/inventory/:id()",
    meta: _91id_93NbBYdjQ3bWMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/inventory/[id].vue")
  },
  {
    name: "fulfilment-inventory",
    path: "/fulfilment/inventory",
    meta: index7rNC8V0ms5Meta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/inventory/index.vue")
  },
  {
    name: "fulfilment-orders-ordercode",
    path: "/fulfilment/orders/:ordercode()",
    meta: _91ordercode_93jFQt1QBPm8Meta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/orders/[ordercode].vue")
  },
  {
    name: "fulfilment-orders",
    path: "/fulfilment/orders",
    meta: indexHM2K0KIXquMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/orders/index.vue")
  },
  {
    name: "fulfilment-orders-new",
    path: "/fulfilment/orders/new",
    meta: newWyI14v5k4kMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/orders/new.vue")
  },
  {
    name: "fulfilment-overview",
    path: "/fulfilment/overview",
    meta: overviewAFJOq3IHmmMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/overview.vue")
  },
  {
    name: "fulfilment-parcels-id",
    path: "/fulfilment/parcels/:id()",
    meta: _91id_93a2DDwM3t0DMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/parcels/[id].vue")
  },
  {
    name: "fulfilment-parcels",
    path: "/fulfilment/parcels",
    meta: indexnAMuLyM5pVMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/parcels/index.vue")
  },
  {
    name: "fulfilment-products-sku",
    path: "/fulfilment/products/:sku()",
    meta: _91sku_93mduq9nezhjMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/products/[sku].vue")
  },
  {
    name: "fulfilment-products-bundle",
    path: "/fulfilment/products/bundle",
    meta: bundleLJq41hR4DQMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/products/bundle.vue")
  },
  {
    name: "fulfilment-products",
    path: "/fulfilment/products",
    meta: indexR4sHvvCr4NMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/products/index.vue")
  },
  {
    name: "fulfilment-products-new",
    path: "/fulfilment/products/new",
    meta: newd82zvOsg1EMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/products/new.vue")
  },
  {
    name: "fulfilment-purchase-orders-id",
    path: "/fulfilment/purchase-orders/:id()",
    meta: _91id_93860tkeAeFSMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/[id].vue")
  },
  {
    name: "fulfilment-purchase-orders",
    path: "/fulfilment/purchase-orders",
    meta: indexECuYI364NAMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/index.vue")
  },
  {
    name: "fulfilment-purchase-orders-new",
    path: "/fulfilment/purchase-orders/new",
    meta: newHGRKWJtw7lMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/purchase-orders/new.vue")
  },
  {
    name: "fulfilment-returns-rma",
    path: "/fulfilment/returns/:rma()",
    meta: _91rma_93KPGlW5uwOGMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/returns/[rma].vue")
  },
  {
    name: "fulfilment-returns",
    path: "/fulfilment/returns",
    meta: index1Hp2cf7H15Meta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/returns/index.vue")
  },
  {
    name: "fulfilment-shipments-id",
    path: "/fulfilment/shipments/:id()",
    meta: _91id_93Q6zqj6oceTMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/shipments/[id].vue")
  },
  {
    name: "fulfilment-shipments",
    path: "/fulfilment/shipments",
    meta: indexNVwJYYBvZLMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/fulfilment/shipments/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexdeoWTC7zgOMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexWgWAImgG8hMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/login/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/usr/src/dashbboard-version-3/pages/logout/index.vue")
  },
  {
    name: "reports",
    path: "/reports",
    meta: index2qjP9U3fzFMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/reports/index.vue")
  },
  {
    name: "settings-business",
    path: "/settings/business",
    meta: index6zlxoHVgPgMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/settings/business/index.vue")
  },
  {
    name: "settings-contacts",
    path: "/settings/contacts",
    meta: indexa1h9qN33mNMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/settings/contacts/index.vue")
  },
  {
    name: "settings-import",
    path: "/settings/import",
    meta: indexvjaAQEjYUkMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/settings/import/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexOB8Up0ah80Meta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/settings/index.vue")
  },
  {
    name: "settings-team",
    path: "/settings/team",
    meta: indexXOKyGQz0zQMeta || {},
    component: () => import("/usr/src/dashbboard-version-3/pages/settings/team/index.vue")
  }
]
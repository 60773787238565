import Cookies from "js-cookie";
import { useClientsStore } from "~/stores/clients.ts";

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const clientsStore = useClientsStore();
  const auth = useAuth();
  const colorMode = useColorMode();
  const runtimeConfig = useRuntimeConfig();

  router.beforeEach(async (to, from) => {
    colorMode.preference = 'light';

    if (auth.loggedIn) {
      // Handle missing clients scenario
      if (!auth.user?.clients || auth.user.clients.length === 0) {
        window.location = `${runtimeConfig.public.accountsUrl}?message=missing_client`;
        return false;
      }

      // Get cookie inside the handler to ensure it's up to date
      const selectedClientCookie = Cookies.get('selected_client_id');

      // Initialize clients if needed
      if (!clientsStore.clients.length) {
        await clientsStore.setClients(auth.user.clients);

        // If cookie exists but differs from auto-selected client, override selection
        if (selectedClientCookie && selectedClientCookie !== clientsStore.selectedClientId) {
          await clientsStore.setSelectedClient(selectedClientCookie);
        }
      }
      // If we have clients loaded but no selected client, set it based on cookie or first client
      else if (!clientsStore.selectedClientId) {
        const clientId = selectedClientCookie || auth.user.clients[0].id;
        await clientsStore.setSelectedClient(clientId);
      }
    }

    return true;
  });
});
